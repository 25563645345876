import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import LeadSpot from '../components/solutions/LeadSpot'
import LeadStreamer from '../components/primary/LeadStreamer'
import ExpandableImage from '../components/ExpandableImage'
import PriceTable from '../components/primary/PriceTable'
import DemoVideo from '../components/primary/DemoVideo'
import CallToAction from '../components/primary/CallToAction'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

const RecipeBuilderPage = () => {
  const { markdownRemark, recipeCardsImage } = useStaticQuery(graphql`
    query RecipeBuilderPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/recipe-builder" } }) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              thumb: childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageExpandable
          }
          benefitsYou {
            blurbsOne {
              title
              text
              icon
            }
          }
          ...PricingFragment
          ...DemoVideoFragment
          ...CallToActionFragment
        }
      }
      recipeCardsImage: file(
        relativePath: { eq: "solutions/recipe-builder/recipe-cards.jpg" }
      ) {
        modal: childImageSharp {
          ...SharpImageModalFragment
        }
        thumb: childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--caterers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        imageFolder="recipe-builder"
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={cn(
                      'col-12 col-md-6 mb-5 d-flex flex-column justify-content-center',
                      { 'order-md-2': !isEven(i) }
                    )}
                  >
                    <h2
                      className="mb-4"
                      dangerouslySetInnerHTML={{ __html: blurb.title }}
                    />
                    <div className="c-postStreamer__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 text-center">
                    <section className="car-card">
                      <ConditionalWrapper
                        condition={blurb.imageExpandable}
                        wrapper={(children) => (
                          <ExpandableImage
                            imageData={blurb.image.modal}
                            bottomRight={isEven(i)}
                          >
                            {children}
                          </ExpandableImage>
                        )}
                      >
                        <Img
                          fluid={blurb.image.thumb.fluid}
                          title={blurb.title}
                          alt={blurb.title}
                          className="c-postStreamer__image img-fluid"
                        />
                      </ConditionalWrapper>
                    </section>
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-12 col-lg-6">
                  <h2 className="mb-4">Recipe Cards</h2>
                  <p>
                    Each recipe you make will automatically generate a Recipe
                    Card. They’re clear, concise and packed full of information:
                  </p>
                  <div className="c-postStreamer__text p-0">
                    <ul style={{ listStyle: 'none' }}>
                      <li>Rolled up ingredient list</li>
                      <li>Optional quantity and costing information</li>
                      <li>Rolled up allergy data for the entire recipe</li>
                      <li>Reference intakes</li>
                      <li>Nutritional summary</li>
                      <li>Dietary information</li>
                      <li>Method & Recipe notes</li>
                    </ul>
                  </div>
                  <p>
                    All summarised onto a single, easy to download and print PDF
                    that can be popped into a kitchen or front of house folder
                    ready to answer (and impress) any enquiring customers.
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <ExpandableImage
                    imageData={recipeCardsImage.modal}
                    bottomRight={true}
                  >
                    <Img
                      fluid={recipeCardsImage.thumb.fluid}
                      title="Recipe Cards produced by Erudus"
                      alt="An example of the recipe cards produced by Erudus."
                      className="c-postStreamer__image img-fluid"
                    />
                  </ExpandableImage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefitsYou.blurbsOne.map((blurb) => (
                <div key={blurb.title} className="c-forBenefitsInfo row">
                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--icon col-12 col-md-3">
                    <img
                      className="c-forBenefitsInfo__icon img-fluid"
                      src={`/images/icons/${blurb.icon}.svg`}
                    />
                  </div>

                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--text col-12 col-md-7">
                    <h2 className="c-forBenefitsInfo__header">{blurb.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <PriceTable
        tag={`Recipe Builder Subscriptions`}
        pricing={pageData.pricing}
      />

      <DemoVideo
        header={pageData.demoVideo.header}
        text={pageData.demoVideo.text}
        blurb={pageData.demoVideo.blurb}
        buttonText={pageData.demoVideo.buttonText}
        buttonLink={pageData.demoVideo.buttonLink}
        videoUrl={pageData.demoVideo.videoUrl}
      />

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default RecipeBuilderPage

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children
